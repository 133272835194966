import Experiencias from '../componentes/Experiencias';

export default function ExperienciasAdmin() {
	return (
		<>
			<h2 className="t2 mb-20">Experiencias</h2>
			<Experiencias />
		</>
	);
}
