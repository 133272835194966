import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BtnNuevo.css';

export default function BtnNuevo() {
	const [visible, setVisible] = useState(false);

	const handleClick = (e) => {
		setVisible(true);
	};

	return (
		<div key={0} onClick={handleClick} className="btnnuevo">
			<span className="btn btn-primary">
				<span>Crl + N</span> Nuevo
			</span>
			<div className={`despliegue ${visible && 'active'}`}>
				<Link to="/admin/experiencia/nueva">Experiencia</Link>
			</div>
		</div>
	);
}
