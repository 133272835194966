import React from 'react';
import './Submenu.css';
import { Link } from 'react-router-dom';

export default function Submenu({ enlaces = {}, children }) {
	return (
		<div className="submenu">
			<div className="main flex">
				<div>
					{enlaces.map((enlace, i) => (
						<Link key={i} to={enlace.url} className="buttons">
							{enlace.text}
						</Link>
					))}
				</div>
				<div className="initial">{children}</div>
			</div>
		</div>
	);
}
