import React, { useState, useEffect } from 'react';
import appFirebase from '../credenciales';
import Experiencia from './Experiencia';
import { getDocs, collection, getFirestore } from 'firebase/firestore';

const db = getFirestore(appFirebase);

export default function Experiencias() {
	const [experiencias, setExperiencias] = useState([]);

	useEffect(() => {
		const getdatos = async () => {
			try {
				const querySnapShot = await getDocs(collection(db, 'experiencias'));
				const docs = [];
				querySnapShot.forEach((doc) => {
					docs.push({ ...doc.data(), id: doc.id });
				});
				setExperiencias(docs);
			} catch (error) {
				console.log(error);
			}
		};

		getdatos();
	}, []);

	return (
		<>
			{experiencias.map((experiencia, i) => (
				<Experiencia
					key={i}
					titulo={experiencia.titulo}
					descripcion={experiencia.descripcion}
					foto={experiencia.foto}
					fecha={experiencia.fecha}
					url={experiencia.url}
				/>
			))}
		</>
	);
}
