import React from 'react';
import html5 from '../assets/html5.svg';
import css from '../assets/css.svg';
import js from '../assets/javascript.svg';
import bootstrap from '../assets/bootstrap.svg';
import react from '../assets/react.svg';
import node from '../assets/node.svg';
import php from '../assets/php.svg';
import wordpress from '../assets/wordpress.svg';
import git from '../assets/git.svg';
import github from '../assets/github.svg';
import jquery from '../assets/jquery.svg';
import mongodb from '../assets/mongodb.svg';
import mysql from '../assets/mysql.svg';
import sqlserver from '../assets/sqlserver.svg';
import csharp from '../assets/csharp.svg';
import firebase from '../assets/firebase.svg';

export default function Tecnologia(props) {
	return (
		<>
			<div className="grid tecnologias">
				<div className="coverfoto">
					<img src={html5} alt="" />
				</div>
				<div className="coverfoto">
					<img src={css} alt="" />
				</div>
				<div className="coverfoto">
					<img src={js} alt="" />
				</div>
				<div className="coverfoto">
					<img src={bootstrap} alt="" />
				</div>
				<div className="coverfoto">
					<img src={react} alt="" />
				</div>
				<div className="coverfoto">
					<img src={node} alt="" />
				</div>
				<div className="coverfoto">
					<img src={firebase} alt="" />
				</div>
				<div className="coverfoto">
					<img src={php} alt="" />
				</div>
				<div className="coverfoto">
					<img src={mysql} alt="" />
				</div>
				<div className="coverfoto">
					<img src={mongodb} alt="" />
				</div>
				<div className="coverfoto">
					<img src={sqlserver} alt="" />
				</div>
				<div className="coverfoto">
					<img src={wordpress} alt="" />
				</div>
				<div className="coverfoto">
					<img src={git} alt="" />
				</div>
				<div className="coverfoto">
					<img src={github} alt="" />
				</div>
				<div className="coverfoto">
					<img src={jquery} alt="" />
				</div>
				<div className="coverfoto">
					<img src={csharp} alt="" />
				</div>
			</div>
		</>
	);
}
