import { useState } from 'react';
import Redes from './Redes';
import Menu from './Menu';
import imagen from '../assets/logodev.svg';
import { Link } from 'react-router-dom';

export default function Nav() {
	const [menu, setMenu] = useState(false);
	const [menublack, setMenublack] = useState(false);

	const openMenu = (e) => {
		setMenu(true);
	};

	const closeMenu = (e) => {
		setMenu(false);
	};

	const handleScroll = (e) => {
		const window = e.currentTarget;
		if (window.scrollY > 20) {
			setMenublack(true);
		} else {
			setMenublack(false);
		}
	};

	window.addEventListener('scroll', (e) => handleScroll(e));

	return (
		<>
			<nav className={menublack ? 'active' : 'false'}>
				<div className="main flex">
					<div className="initial mr-20">
						<Link to="/">
							<img style={{ height: '50px' }} src={imagen} alt="" />
						</Link>
					</div>
					<div className="m-none">
						<Redes />
					</div>
					<div>{/* espacio */}</div>
					<div className="initial">
						<span onClick={openMenu} className="btn-menu">
							<ion-icon name="menu" style={{ fontSize: '30px', cursor: 'pointer' }}></ion-icon>
						</span>
					</div>
				</div>
			</nav>
			<Menu estado={menu} closeMenu={closeMenu} />
		</>
	);
}
