import React from 'react'
import imagen from '../assets/13.svg'

export default function Error() {
  return (
    <>
      <div className='center'>
        <img src={imagen} style={{width:"100%", maxWidth:"500px"}} alt="" />
        <div>
          <h1 className="t2">
            Sitio en construccion, Upss!!
          </h1>
        </div>
      </div>
    </>
  )
}
