import React from 'react';
import './Menu.css';
import foto from '../assets/1516768789422.jpg';
import Redes from './Redes';
import { Link } from 'react-router-dom';

export default function Menu({ estado, closeMenu }) {
	return (
		<>
			<div className={`sombra ${estado && 'active'}`} onClick={closeMenu}></div>
			<div id="menu" className={`flex-column ${estado && 'active'}`}>
				<div className="initial info flex mb-20">
					<div className="initial mr-10">
						<div className="coverfoto">
							<img src={foto} alt="Starkin Miguel De la Cruz Reyes" />
						</div>
					</div>
					<div>
						<div className="bold-500">Starking Miguel</div>
						<div className="lt-text minleng">Desarrollador Web</div>
					</div>
				</div>
				<div>
					<ul>
						<li>
							<Link onClick={closeMenu} to="/">
								Inicio
							</Link>
						</li>
						<li>
							<Link onClick={closeMenu} to="/contacto">
								Contacto
							</Link>
						</li>
						<li>
							<Link onClick={closeMenu} to="/blog">
								Blog
							</Link>
						</li>
						<li>
							<Link onClick={closeMenu} to="/portafolio">
								Portafolios
							</Link>
						</li>
						{/* <li>
							<Link onClick={closeMenu} to="/admin">
								Administrador
							</Link>
						</li> */}
					</ul>
				</div>
				<div className="initial center">
					<Redes />
				</div>
			</div>
		</>
	);
}
