import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './Responsive.css';
import Footer from './componentes/Footer';
import Inicio from './pages/Inicio';
import Error from './pages/Error';
import Nav from './componentes/Nav';
import Contacto from './pages/Contacto';
import Admin from './pages/Admin';
import NuevaExperiencia from './pages/NuevaExperiencia';
import Mensajes from './pages/Mensajes';

export default function App() {
	return (
		<>
			<Router>
				<Nav />
				<Routes>
					<Route exact path="/" element={<Inicio />} />
					<Route exact path="/contacto" element={<Contacto />} />
					<Route exact path="/admin" element={<Admin />} />
					<Route exact path="/admin/experiencia/nueva" element={<NuevaExperiencia />} />
					<Route exact path="/admin/mensajes" element={<Mensajes />} />
					<Route path="*" element={<Error />} />
				</Routes>
			</Router>
			<Footer />
		</>
	);
}
