import React, { useState, useEffect } from 'react';
import appFirebase from '../credenciales';
import { getFirestore, getDocs, collection } from 'firebase/firestore';

const db = getFirestore(appFirebase);

export default function Mensajes() {
	const [mensajes, setMensajes] = useState([]);

	useEffect(() => {
		const getdatos = async () => {
			const querySnapShot = await getDocs(collection(db, 'mensajes'));
			const docs = [];
			querySnapShot.forEach((doc) => {
				docs.push({ ...doc.data(), id: doc.id });
			});
			setMensajes(docs);
		};

		getdatos();
	}, []);

	return (
		<>
			<div className="pth main">
				{mensajes.map((mensaje) => (
					<div>{mensaje.correo}</div>
				))}
			</div>
		</>
	);
}
