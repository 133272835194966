import React from 'react'
import Redes from './Redes'
import './Footer.css'

export default function Footer() {
  return (
    <>
      <footer className='initial'>
        <div className="main flex">
          <div className='mr-20'>
            Todos los derechos reservados &copy; 2022
          </div>
          <div className='initial'>
            <Redes />
          </div>
        </div>
      </footer>
    </>
  )
}
