import React from 'react';
import './Redes.css';

export default function Redes() {
	return (
		<>
			<div className="redes">
				<a target="_blank" href="https://instagram.com/by_elyos" rel="noreferrer">
					<ion-icon name="logo-instagram"></ion-icon>
				</a>
				<a
					target="_blank"
					href="https://www.linkedin.com/in/starking-miguel-de-la-cruz/"
					rel="noreferrer"
				>
					<ion-icon name="logo-linkedin"></ion-icon>
				</a>
				<a target="_blank" href="https://starking.masterwebrd.com" rel="noreferrer">
					<ion-icon name="globe-outline"></ion-icon>
				</a>
				<a target="_blank" href="https://github.com/starkingize" rel="noreferrer">
					<ion-icon name="logo-github"></ion-icon>
				</a>
				<a target="_blank" href="https://twitter.com/by_elyos" rel="noreferrer">
					<ion-icon name="logo-twitter"></ion-icon>
				</a>
			</div>
		</>
	);
}
