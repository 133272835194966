import React, { useState } from 'react';
import appFirebase from '../credenciales';
import { ref, getStorage, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFirestore, addDoc, collection, updateDoc, doc } from 'firebase/firestore';
import { Toaster, toast } from 'react-hot-toast';
import { fechaActual } from '../helpers/Funciones';

const db = getFirestore(appFirebase);
const storage = getStorage(appFirebase);

export default function NuevaExperiencia() {
	const [form, setForm] = useState({});
	const [progress, setProgress] = useState(0);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setForm({
			...form,
			[name]: value,
		});
	};

	const handleUpload = async (file, id) => {
		if (!file) return;
		const storageRef = ref(storage, `public/img/${file.name}`);
		const storageTask = uploadBytesResumable(storageRef, file);
		storageTask.on(
			'state_changed',
			(snapshot) => {
				const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				setProgress(prog);
			},
			(err) => console.log(err),
			() => {
				getDownloadURL(storageTask.snapshot.ref).then((url) => {
					const datos = {
						foto: url,
						fecha_registro: fechaActual(),
					};
					updateDoc(doc(db, 'experiencias', id), datos);
				});
			}
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const loading = toast.loading('Publicando...');
		// publicando
		const result = await addDoc(collection(db, 'experiencias'), form);
		await handleUpload(e.target[1].files[0], result.id);
		// reiniciando formulario
		e.target.reset();
		setForm({});
		// terminando notificaciones
		toast.dismiss(loading);
		toast.success('Experiencia registrada con éxito');
	};

	return (
		<div className="main">
			<Toaster />
			<div className="pth">
				<div className="mb-20">
					<h2 className="t2">Nueva Experiencia {progress}</h2>
				</div>
				<form onSubmit={handleSubmit} autoComplete="off">
					<div className="mb-10">
						<div className="label">Titulo</div>
						<div className="campo">
							<input
								type="text"
								name="titulo"
								placeholder="Introduce el titulo"
								onKeyUp={handleChange}
							/>
						</div>
					</div>
					<div className="mb-10">
						<div className="label">Imagen</div>
						<div className="campo">
							<input type="file" name="imagen" />
						</div>
					</div>
					<div className="mb-10">
						<div className="label">Descripción</div>
						<div className="campo">
							<input
								type="text"
								name="descripcion"
								placeholder="Describe un poco"
								onKeyUp={handleChange}
							/>
						</div>
					</div>
					<div className="mb-10">
						<div className="label">Fecha</div>
						<div className="campo">
							<input type="text" name="fecha" onKeyUp={handleChange} />
						</div>
					</div>
					<div className="mb-10">
						<div className="label">Url</div>
						<div className="campo">
							<input type="url" name="url" placeholder="http://" onKeyUp={handleChange} />
						</div>
					</div>
					<div className="flex">
						<div></div>
						<div className="initial">
							<button className="btn btn-primary">
								<span>Enter</span>
								Publicar
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
