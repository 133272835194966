import React from 'react';
import './HeaderMedio.css';

export default function HeaderMedio({ fondo }) {
	return (
		<div id="HeaderMedio" style={{ backgroundImage: `url("${fondo}")` }}>
			<div style={{ backgroundColor: 'rgba(0,0,0,.5)', height: '100%' }}></div>
		</div>
	);
}
