import React from 'react';
import { Helmet } from 'react-helmet';
import FormContacto from '../componentes/FormContacto';
import HeaderMedio from '../componentes/HeaderMedio';
import imagen from '../assets/iconodev.png';

export default function Contacto() {
	return (
		<>
			<Helmet defer={false}>
				<title>Contacto | Starking Desarrollo Web</title>
				<meta
					name="description"
					content="Desarrollador de web senior, tecnologías: HTML5, CSS3, Javascript, Jquery, PHP, C#, React, SQLserver, MySQL, Git, GitHub y Mas"
				/>
				<meta property="og:title" content="Contacto | Starking Desarrollo Web" />
				<meta property="og:type" content="website" />
				<meta property="og:url" content=" https://starking.masterwebrd.com/contacto" />
				<meta property="og:image" content={imagen} />
			</Helmet>
			<HeaderMedio fondo="https://c1.wallpaperflare.com/preview/623/487/747/technology-code-coding-computer.jpg" />
			<FormContacto />
		</>
	);
}
