import React, { useState } from 'react';
import './FormContacto.css';
import Redes from './Redes';
import appFirebase from '../credenciales';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { Toaster, toast } from 'react-hot-toast';

const db = getFirestore(appFirebase);

export default function FormContacto() {
	const [form, setForm] = useState({});

	const handleSubmit = async (e) => {
		e.preventDefault();
		const toastId = toast.loading('Enviando...');
		await addDoc(collection(db, 'mensajes'), form);
		e.target.reset();
		toast.dismiss(toastId);
		setForm({});

		toast('Mensaje enviado con éxito!', {
			icon: '👏',
			style: {
				borderRadius: '10px',
				background: '#333',
				color: '#fff',
			},
		});
	};

	const hanldeChange = (e) => {
		const { name, value } = e.target;
		setForm({
			...form,
			[name]: value,
			fecha: Date.now(),
		});
	};

	return (
		<>
			<div className="main">
				<form onSubmit={handleSubmit} className="mb-20" autoComplete="off">
					<div className="flex aling-top" id="formcontact">
						<div>
							<div>
								<div className="title">Contactar</div>
							</div>
							<div className="mb-20">
								<input
									onChange={hanldeChange}
									type="text"
									name="nombres"
									required
									placeholder="Nombres"
								/>
							</div>
							<div className="mb-20">
								<input
									onChange={hanldeChange}
									type="email"
									name="correo"
									required
									placeholder="Correo"
								/>
							</div>
							<div className="mb-20">
								<input
									onChange={hanldeChange}
									type="text"
									name="asunto"
									required
									placeholder="Asunto"
								/>
							</div>
							<div className="mb-20">
								<textarea
									onChange={hanldeChange}
									name="mensaje"
									placeholder="Mensaje"
									id="mensaje"
									cols="3"
									rows="20"
								></textarea>
							</div>
							<div>
								<button className="btn btn-primary">
									<span>Enter</span>
									Enviar
								</button>
							</div>
						</div>
						<div className="flex-column" style={{ backgroundColor: 'hwb(225deg 9% 88%)' }}>
							<div>
								<div className="title mb-10">Informacion de contacto</div>
								<div className="mb-20">
									Escoja la opcion mas confiable para comunicarse con nosotros.
								</div>
								<div>
									<ul id="lista">
										<li className="flex">
											<div className="initial">
												<span>
													<ion-icon name="location-outline"></ion-icon>
												</span>
											</div>
											<div>
												<strong>Direccion: </strong> Santo Domingo Norte, Republica Dominicana,
												11300
											</div>
										</li>
										<li className="flex">
											<span>
												<ion-icon name="call-outline"></ion-icon>
											</span>{' '}
											<strong>Telefono: </strong> 1+(829)790-0676
										</li>
										<li className="flex">
											<span>
												<ion-icon name="send-outline"></ion-icon>
											</span>{' '}
											<strong>Email: </strong> starkingize@gmail.com
										</li>
										<li className="flex">
											<span>
												<ion-icon name="location-outline"></ion-icon>
											</span>{' '}
											<strong>Web: </strong> <a href="/">starking.masterwebrd.com</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="flex initial">
								<div className="minleng">Sociales</div>
								<div className="initial">
									<Redes />
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>

			<Toaster />
		</>
	);
}
