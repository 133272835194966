import React from 'react';
import './Header.css';
import imagen from '../assets/1516768789422.jpg';

export default function Header(props) {
	return (
		<>
			<header style={{ backgroundImage: `url("${props.fondo}")` }}>
				<div>
					<div className="main detalles flex">
						<div
							className="initial coverfoto mr-20 m-none"
							style={{ width: '200px', height: '200px' }}
						>
							<img src={imagen} alt="" />
						</div>
						<div>
							<div className="mb-20 size-14 white-hide">
								Desarrollar de Sistemas Informáticos
							</div>
							<h1 className="t1">
								<span className="text-pink">HOLA</span> MI NOMBRE <br /> ES{' '}
								{props.titulo}.
							</h1>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
