import React from 'react';
import BtnNuevo from '../componentes/BtnNuevo';
import Submenu from '../componentes/Submenu';
import ExperienciasAdmin from './ExperienciasAdmin';

export default function Admin() {
	return (
		<>
			<div className="pth">
				<Submenu
					enlaces={[
						{ text: 'experiencias', url: '/admin' },
						{ text: 'proyectos', url: '/admin/proyectos' },
						{ text: 'habilidades', url: '/admin/habilidades' },
						{ text: 'blog', url: '/admin/blog' },
						{ text: 'mensajes', url: '/admin/mensajes' },
					]}
				>
					<BtnNuevo />
				</Submenu>
				<div className="main">
					<ExperienciasAdmin />
				</div>
			</div>
		</>
	);
}
