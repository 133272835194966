import React from 'react'
import './Experiencia.css'

export default function Experiencia(props) {
    return (
        <a href={props.url} target="_blank" rel="noreferrer" className='experiencia flex mb-20'>
            <div className='initial mr-10'>
                <div className="coverfoto" style={{ width: "70px", height: "70px" }}>
                    <img src={props.foto} alt="" />
                </div>
            </div>
            <div>
                <div className="bold-500 mb-5" style={{fontSize:"18px"}}>
                    {props.titulo}
                </div>
                <div className='mb-5'>
                    {props.descripcion}
                </div>
                <div>
                    {props.fecha}
                </div>
            </div>
        </a>
    )
}
